// Base

html {
  font-size: 6px;

  @include media-breakpoint-up(md) {
    font-size: 8px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 10px;
  }
}

html, body {
  overflow: hidden;
}

a {
  text-decoration: none !important;
}

* {
  outline: none !important;
}

.bg-image {
  background-size: cover;
  background-color: $gray-300;
  background-repeat: no-repeat;
  background-position: center center;
}

#main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.d-done {
  display: none !important;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
