// Functions

@function px($number, $ratio: 1) {
  $result: ($number/1080 * 100) * $ratio;
  @return $result+vh;
}

@function pxw($number, $ratio: 1) {
  $result: ($number/1920 * 100) * $ratio;
  @return $result+vw;
}
