// Variables

$cdn: 'https://cdn.vn.garenanow.com/web/fo3/fo3/fo4-vn-legend';

$colorYellow: #ffaf00;
$colorYellow2: #c7807c;
$colorOrange: #ff6008;
$colorOrange2: #cd360d;
$colorRed: #730712;

$body-bg: $black;
$body-color: $white;

$font-family-mallory: 'Mallory Cond', sans-serif;
$font-family-monument: 'Monument Extended Bold', sans-serif;

$font-family-base: $font-family-mallory;
$font-size-base: 1.6rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
  xxxl: 1800px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
  xxxl: 1740px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");
