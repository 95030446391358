// Key visual

.kv {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: url('#{$cdn}/img/kv/background.jpg') 0 0/100% 100% no-repeat;

  &>* {
    user-select: none;
    pointer-events: none;
    position: absolute;

    img {
      width: 100%;
    }
  }

  &__ball {
    width: px(213);
    top: px(154);
    right: px(550);
    animation-delay: 1600ms;

    img {
      animation: shaking 1600ms ease-in-out infinite;
    }
  }

  &__trophy {
    width: px(358);
    top: px(89);
    left: px(500);
    animation-delay: 1600ms;

    img {
      animation: shaking 1600ms ease-in-out infinite;
    }
  }

  &__headline {
    width: px(1086);
    bottom: px(52);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 100;
    animation-delay: 1200ms;
  }

  &__lightning {
    width: px(1658);
    z-index: 110;
    bottom: px(81);
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &__players {
    width: px(1552);
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 60;
    animation-delay: 500ms;
  }

  &__smoke-right {
    width: px(158);
    bottom: 0;
    right: px(600);
    z-index: 100;
  }

  &__smoke-left {
    width: px(140);
    bottom: 0;
    left: px(600);
    z-index: 100;
  }

  &__badge {
    width: px(202);
    bottom: px(408);
    left: px(68);
    animation-delay: 1600ms;
  }

  &__date {
    width: px(112);
    bottom: px(36);
    right: px(43);
  }

  &__scroll {
    width: px(170);
    bottom: px(530);
    right: px(60);
    animation: twinkle 2000ms infinite;
  }
}
