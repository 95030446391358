// Header

.header {
  $self: '.header';

  &>* {
    position: absolute;
    z-index: 200;
  }

  &__legend {
    text-align: right;
    font-size: px(27.97);
    color: $colorOrange;
    line-height: .9em;
    font-family: $font-family-monument;
    top: px(36);
    right: pxw(144);
  }

  &__logo {
    top: px(28);
    left: px(29);

    img {
      width: px(67);
    }
  }

  &__download, &__share {
    display: inline-block;
    transition: all 500ms ease;

    img {
      &:nth-child(1) {
        height: px(52);
        display: none;
      }

      &:nth-child(2) {
        height: px(51);
        display: inline-block;
      }
    }
  }

  &__download {
    top: px(37);
    right: px(24);
  }

  &__share {
    top: px(95);
    right: px(24);
  }

  &__menu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    transform-origin: top left;
    top: px(300);
    right: px(-275);

    li {
      & ~ li {
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          width: px(6);
          height: px(6);
          position: absolute;
          top: calc(50% - #{px(6/2)});
          left: px(-3);
          border-radius: 50%;
          background: $colorYellow2;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $colorYellow2 !important;
        height: px(22);
        font-size: px(16);
        line-height: 1.1em;
        border: px(2.5) solid transparent;
        border-radius: px(12);
        padding: 0 px(7) px(2);
        text-transform: uppercase;
      }

      &.active {
        padding: 0 px(7);

        a {
          border-color: $colorYellow;
          color: $colorYellow !important;
        }
      }
    }
  }

  &.is-home {
    #{$self}__download, #{$self}__share {
      img {
        &:nth-child(1) {
          display: inline-block;
        }

        &:nth-child(2) {
          display: none;
        }
      }
    }

    #{$self}__download {
      top: px(37);
      right: px(194);
    }

    #{$self}__share {
      top: px(37);
      right: px(54);
    }
  }
}
