// Scrollbar

.ScrollbarsCustom {
  $self: '.ScrollbarsCustom';
  $headSpace: px(0);
  $footSpace: px(0);
  $edgeSpace: pxw(-20);
  $size: px(7);
  $backgroundColor: $white;

  &-Track {
    background: none !important;
    border-radius: 0 !important;
  }

  &-Thumb {
    border-radius: 0 !important;
    background: $backgroundColor !important;
  }

  &.trackYVisible {
    #{$self}-Wrapper {
      right: 0 !important;
    }

    #{$self}-TrackY {
      right: $edgeSpace !important;
      width: $size !important;
      height: calc(100% - #{$headSpace} - #{$footSpace}) !important;
      top: $headSpace !important;
    }
  }

  &.trackXVisible {
    #{$self}-Wrapper {
      bottom: 0 !important;
    }

    #{$self}-TrackX {
      bottom: $edgeSpace !important;
      height: $size !important;
      width: calc(100% - #{$headSpace} - #{$footSpace}) !important;
      left: $headSpace !important;
    }
  }
}
