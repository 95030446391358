// Player

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__left, &__right {
    height: px(1080);
    position: absolute;
    top: 0;
    overflow: hidden;
  }

  &__left {
    width: pxw(810);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__right {
    width: pxw(1920 - 810);
    right: 0;
    background: $colorRed;
  }

  &__background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    pointer-events: none;
  }

  &__number {
    font-size: px(228.94);
    font-family: $font-family-monument;
    color: $colorOrange2;
    line-height: 1em;
    position: absolute;
    top: 0;
    right: px(50);
  }

  &__avatar {
    position: relative;
    z-index: 1;
    padding-top: px(100);

    img {
      max-height: px(899);
    }
  }

  &__stats {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    pointer-events: none;
    z-index: 9;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__info {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;

    &--frame {
      width: 100%;
      height: 100%;
      user-select: none;
      pointer-events: none;
    }
  }

  &__moments {
    width: pxw(1008);
    height: px(246);
    position: absolute;
    top: px(348);
    left: 0;

    &--title {
      font-size: pxw(28);
      font-weight: 700;
      color: $colorYellow;
      position: absolute;
      top: px(28);
      left: pxw(59);
      line-height: 1.2em;
    }

    &--content {
      position: absolute;
      top: px(28);
      left: pxw(200);
      width: pxw(739);
      height: px(190);
      touch-action: none;

      p {
        font-size: px(25);
        line-height: 1.4em;
        margin: 0;
      }
    }
  }

  &__achievements {
    width: pxw(1008);
    height: px(273);
    position: absolute;
    top: px(596);
    left: 0;

    &--title {
      font-size: pxw(28);
      font-weight: 700;
      color: $colorYellow;
      position: absolute;
      top: px(28);
      left: pxw(59);
      line-height: 1.2em;
    }

    &--content {
      position: absolute;
      top: px(28);
      left: pxw(200);
      width: pxw(739);
      height: px(220);
      touch-action: none;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: px(25);

        li {
          line-height: 1.5em;
          position: relative;
          padding-left: px(37);

          &:before {
            content: '';
            display: inline-block;
            width: px(23);
            height: px(22);
            position: absolute;
            top: px(6);
            left: 0;
            background: url('#{$cdn}/img/icon-star.png') 0 0/100% 100% no-repeat;
          }
        }
      }
    }
  }

  &__toggle {
    position: fixed;
    bottom: px(25);
    left: pxw(683);
    z-index: 20;

    img {
      height: px(105);
    }
  }

  &__header {
    width: pxw(1009);
    height: px(348);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    z-index: 10;
  }

  &__footer {
    width: 100%;
    height: px(211);
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    z-index: 10;
  }

  &__name {
    width: 100%;
    position: absolute;
    left: pxw(55);
    top: calc(50% - #{px(211/2)});

    img {
      height: px(211);
      max-width: 90%;
    }
  }

  &__year {
    position: absolute;
    top: px(15);
    right: 0;

    img {
      width: pxw(920);
      height: px(206);
    }
  }

  &__decor {
    width: pxw(1008);
    height: px(870);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    &:before, &:after {
      content: '';
      display: block;
      width: pxw(226);
      height: 100%;
      position: absolute;
      top: 0;
    }

    &:before {
      left: pxw(-10);
      animation: shaking 1000ms ease-in-out infinite,
      decorLeft 1000ms ease-in-out infinite,
      blink 800ms ease-in-out infinite;
      background: url('#{$cdn}/img/decor-player-1.png') 0 0/100% 100% no-repeat;
    }

    &:after {
      right: pxw(-10);
      animation: shaking 1000ms ease-in-out infinite,
      decorRight 1000ms ease-in-out infinite,
      blink 800ms ease-in-out infinite;
      background: url('#{$cdn}/img/decor-player-2.png') 0 0/100% 100% no-repeat;
    }
  }

  &__view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__video {
    height: 100%;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    transition: none !important;

    img, video {
      height: 100%;
      user-select: none;
      pointer-events: none;
    }
  }

  &__banner {
    $self: '.player__banner';

    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 200;
    transition: transform 300ms ease;

    &--img {
      display: inline-block;

      img {
        width: pxw(1108);
      }
    }

    &--close {
      display: inline-block;
      position: absolute;
      top: calc(50% - #{px(50/2)});
      left: px(-30);
      transition: all 300ms ease;

      &.closed {
        transform: rotate(180deg);
      }

      img {
        height: px(50);
      }
    }

    &.closed {
      transform: translateX(100%);

      #{$self}--close {
        left: px(-60);
      }
    }
  }
}
