// Fonts

@font-face {
  font-family: 'Mallory Cond';
  src: url('#{$cdn}/fonts/MalloryCondensed-Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mallory Cond';
  src: url('#{$cdn}/fonts/MalloryXCondensed-Black.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Extended Bold';
  src: url('#{$cdn}/fonts/MonumentExtended-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
